<template>
  <div class="asset-list">
    <!-- 汇总信息卡片 -->
    <el-card class="summary-card">
      <template #header>
        <span>资产汇总</span>
      </template>
      <p>总资产数: {{ summary.totalAssets }}</p>
      <p>总价值: ¥{{ summary.totalValue }}</p>
    </el-card>

    <!-- 资产列表 -->
    <div class="asset-grid">
      <el-card v-for="asset in assets" :key="asset.id" class="asset-card">
        <template #header>
          <span>{{ asset.name }}</span>
        </template>
        <p>编号: {{ asset.id }}</p>
        <p>类型: {{ asset.type }}</p>
        <p>价值: ¥{{ asset.value }}</p>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const summary = ref({ totalAssets: 0, totalValue: 0 });
const assets = ref([]);

const fetchAssets = async () => {
  try {
    const response = await axios.get('/api/assets');
    assets.value = response.data;
    summary.value.totalAssets = response.data.length;
    summary.value.totalValue = response.data.reduce((sum, asset) => sum + asset.value, 0);
  } catch (error) {
    console.error('获取资产数据失败:', error);
  }
};

onMounted(fetchAssets);
</script>

<style scoped>
.asset-list {
  padding: 20px;
}

.summary-card {
  margin-bottom: 20px;
}

.asset-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.asset-card {
  text-align: left;
}
</style>